import React from 'react';
// import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next'
import { useLocalization } from 'gatsby-theme-i18n'
import Layout from '../layouts/index';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEnvelope, faGlobeAmericas, faCheckDouble, faHandshake, faDatabase, faUserShield, faGavel, faFileSignature } from '@fortawesome/free-solid-svg-icons';
// import BlogArticle from '../components/Blog';
// import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Slack01 from '../images/slack/slack-integration-01.png';
import Slack02 from '../images/slack/slack-integration-02.png';
import Slack2 from '../images/slack/slack-integration-2.jpg';
import Slack3 from '../images/slack/slack-integration-3.jpg';
import Slack4 from '../images/slack/slack-integration-4.jpg';
import Slack5 from '../images/slack/slack-integration-5.jpg';
import Slack7 from '../images/slack/slack-integration-7.jpg';
import Slack8 from '../images/slack/slack-integration-8.jpg';
// import Slack9 from '../images/slack/slack-integration-9.jpg';
// import Slack6 from '../images/slack/slack-integration-6.jpg';
// import Slack1 from '../images/slack/slack-integration-1.jpg';
// import Slack10 from '../images/slack/slack-integration-10.jpg';
// import Slack11 from '../images/slack/slack-integration-11.jpg';


function SlackIntegration({ location }) {
  const { t } = useTranslation();
  const { locale } = useLocalization()
  const slack = {
    es: [
      {
        title: '1. Habilita la integración con Slack',
        text: 'Para habilitar Slack debes ir a tu perfil y dentro de la pestaña de integraciones dar clic en el botón gris.',
        image: Slack01,
      },
      {
        title: '2. Selecciona el canal de Slack al que deseas lleguen las notificaciones',
        text: 'Ahora deberás seleccionar el canal de Slack en donde deseas que te lleguen las notificaciones de Trato.',
        image: Slack3,
      },
      {
        title: '3. Ahora podrás visualizar las alertas en el canal de Slack',
        text: 'En Slack selecciona el canal que elegiste en la configuración para visualizar las notificaciones de Trato.',
        image: Slack5,
      },
      {
        title: '4. Comandos en Slack',
        text: 'Puedes utilizar "Slash Commands" para ver los reportes sobre el estatus de tus contratos y para hacer busquedas a tus contratos. /reports: devuelve la lista de contratos por estatus. /contract [name]: Devuelve la información sobre un contrato en especifico. /contracts [name]: Devuelve la lista de los contratos que coincidan con tu busqueda',
        image: Slack02,
      },
      {
        title: '5. Inicia sesión en tu cuenta',
        text: 'Al utilizar por primera vez un "Slash command", slack te pedirá que inicies sesión con tu cuenta de Trato para vincular ambas cuentas.',
        image: Slack2,
      },
      {
        title: '6. Se ha integrado correctamente Slack',
        text: 'Una vez que inicies sesión, Slack estará integrado a Trato.',
        image: Slack8,
      },
      {
        title: '7. Consulta los reportes ',
        text: 'Vuelve a ejecutar un "Slash command" para obtener la información de tus contratos.',
        image: Slack7,
      },
      {
        title: '8. Consulta los contratos',
        text: 'Puedes consultar contratos individuales para ver información detallada si utilizas el comando "/contract"',
        image: Slack4,
      },
    ],
    en: [
      {
        title: '1. Enable integration with Slack',
        text: 'To enable Slack you must go to your profile and within the integrations tab click on the gray button.',
        image: Slack01,
      },
      {
        title: '2. Select the Slack channel you want notifications to be sent to.',
        text: 'Now you must select the Slack channel to which you want TRATO notifications to be sent to.',
        image: Slack3,
      },
      {
        title: '3. Now you can view the alerts on the Slack channel',
        text: 'In Slack select the channel you chose in the settings to display TRATO notifications.',
        image: Slack5,
      },
      {
        title: '4. slash Commands',
        text: 'You can use "Slash Commands" to view reports on the status of your contracts and to search for your contracts. / reports: returns the list of contracts by status. / contract [name]: Returns the information about a specific contract. / contracts [name]: Returns the list of contracts that match your search',
        image: Slack02,
      },
      {
        title: '5. Log in to your account',
        text: 'When you first use a "Slash command", slack will ask you to log in with your TRATO account to link both accounts',
        image: Slack2,
      },
      {
        title: '6. Slack has been successfully integrated',
        text: 'Once you log in, Slack will be integrated to your TRATO account',
        image: Slack8,
      },
      {
        title: '7. Check the reports',
        text: 'Run a "Slash command" again to get your contract information.',
        image: Slack7,
      },
      {
        title: '8. Check the contracts',
        text: 'You can consult individual contracts to see detailed information if you use the command "/contract',
        image: Slack4,
      },
    ],
    pt: [
      {
        title: '1. Habilitar integración con Slack',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ullamcorper dictum porttitor. Fusce nunc augue, sollicitudin quis lacus sed, pulvinar pharetra diam. Suspendisse porttitor eros sed ipsum venenatis, eget fringilla nunc tincidunt.',
        image: Slack01,
      },
      {
        title: '2. Selecciona el canal de Slack a donde llegarán las notificaciones',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ullamcorper dictum porttitor. Fusce nunc augue, sollicitudin quis lacus sed, pulvinar pharetra diam. Suspendisse porttitor eros sed ipsum venenatis, eget fringilla nunc tincidunt.',
        image: Slack3,
      },
      {
        title: '3. Ahora podrás visualizar las alertas en el canal de Slack que selccionaste',
        text: 'Recibiras alertas cuando se completen, firmen, finalicen tus contrato',
        image: Slack5,
      },
      {
        title: '5. slash Commands',
        text: 'Lista - report lista contratos por status - contract te da la información sobre un contrato en especifico- contracts lista los contratos que coincidan con tu busqueda',
        image: Slack02,
      },
      {
        title: '6. Inicia sesión en tu cuenta',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ullamcorper dictum porttitor. Fusce nunc augue, sollicitudin quis lacus sed, pulvinar pharetra diam. Suspendisse porttitor eros sed ipsum venenatis, eget fringilla nunc tincidunt.',
        image: Slack2,
      },
      {
        title: '7. Se ha integrado correctamente Slack',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ullamcorper dictum porttitor. Fusce nunc augue, sollicitudin quis lacus sed, pulvinar pharetra diam. Suspendisse porttitor eros sed ipsum venenatis, eget fringilla nunc tincidunt.',
        image: Slack8,
      },
      {
        title: '8. Consulta los reportes ',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ullamcorper dictum porttitor. Fusce nunc augue, sollicitudin quis lacus sed, pulvinar pharetra diam. Suspendisse porttitor eros sed ipsum venenatis, eget fringilla nunc tincidunt.',
        image: Slack7,
      },
      {
        title: '9. Consulta los contratos',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ullamcorper dictum porttitor. Fusce nunc augue, sollicitudin quis lacus sed, pulvinar pharetra diam. Suspendisse porttitor eros sed ipsum venenatis, eget fringilla nunc tincidunt.',
        image: Slack4,
      },
    ],
  };

  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing bg-grey" inverted border location={location}>
      <section>
        <div className="container mt-5">
          <div className="card bg-light w-75 border rounded mx-auto">
            <div className="card-header p-4">
              <h1 className="font-weight-bold">{t('slack.title')}</h1>
              <p className="lead text-muted">{t('slack.subtitle')}</p>
            </div>
            <div className="card-body p-4">
              {
                slack[locale].map((instruction, i) => (
                  <div key={`ins-${locale}-${i}`} className="py-6 border-bottom">
                    <h5 className="font-weight-bold">{instruction.title}</h5>
                    <p className="text-muted">{instruction.text}</p>
                    <img src={instruction.image} className="img-fluid rounded border mt-2" alt="slack" />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default SlackIntegration;
